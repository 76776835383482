import React from "react";

import logo from "../../Assets/Logo.png";
import logo1 from "../../Assets/Logo1.png";

const Navbar = () => {
  return (
    <div className="w-full flex justify-between px-7">
      <img src={logo1} className="md:w-[27%] 2xl:w-[7%] ml-5 mt-4" />

      <img src={logo} className="md:w-[39%] 2xl:w-[22%] ml-5 mt-4" />
    </div>
  );
};

export default Navbar;
