import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DeltaAltitudeGraph = ({ parsedData, labels }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Change in Altitude",
        font: {
          weight: "bold",
          size: 18,
        },
      },
      legend: {
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        title: {
          display: true,
          text: "Altitude (ft)",
          font: {
            weight: "bold",
            size: 15,
          },
        },
        position: "left",
      },
      x: {
        title: {
          display: true,
          text: "Time (UTC)",
          font: {
            weight: "bold",
            size: 15,
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Change in Altitude (ft)",
        data: parsedData.deltaAltitude,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y",
      },
    ],
  };

  return <Line data={data} options={options} />;
};

export default DeltaAltitudeGraph;
