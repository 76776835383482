import L from "leaflet";

export const generateIcon = (iconType, anomaly, anomMode, selected) => {
  let img;
  let size;

  if (iconType == "dot") {
    return L.icon({
      iconUrl: require("../../Assets/dot.png"),
      iconSize: 10,
    });
  }

  if (iconType === "Flights" && anomaly && anomMode) {
    img = require("../../Assets/AnomalyPlane.png");
    size = 30;
  } else if (iconType === "Flights") {
    img = require("../../Assets/Plane.png");
    size = 30;
  }

  if (iconType === "Marine" && selected) {
    img = require("../../Assets/Ships/SelectedShip.png");
    size = 30;
  } else if (iconType === "Marine") {
    if (iconType === "Marine" && anomaly && anomMode) {
      img = require("../../Assets/Ships/AnomalyShip.png");
      size = 30;
    } else if (iconType === "Marine") {
      img = require("../../Assets/Ships/Ship.png");
      size = 30;
    }
  }

  if (iconType === "Space") {
    img = require("../../Assets/Space/Satellite.png");
    size = 30;
  }

  return L.icon({
    iconUrl: img,
    iconSize: size,
  });
};
