import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DecisionGraph = ({ parsedData, labels }) => {
  const [boundary, setBoundary] = useState([]);

  useEffect(() => {
    let newBoundary = [];
    labels.map(() => {
      newBoundary.push(parsedData.decisionBoundary);
    });
    setBoundary(newBoundary);
  }, [parsedData]);

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,

    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        font: {
          weight: "bold",
          size: 18,
        },
        display: true,
        text: "Decision Statistic",
      },
      legend: {
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        title: {
          display: true,
          text: "Decision Statistic",
          font: {
            weight: "bold",
            size: 15,
          },
        },
        position: "left",
      },
      x: {
        title: {
          display: true,
          text: "Time (UTC)",
          font: {
            weight: "bold",
            size: 15,
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Cumulative Decision Statistic",
        data: parsedData.decision,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y",
      },
      {
        label: "Anomaly Decision Boundary",
        data: boundary,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
      },
    ],
  };

  console.log(data, "DECISION DATA GRAPH");

  return <Line data={data} options={options} />;
};

export default DecisionGraph;
