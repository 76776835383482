import React, { useState, useEffect, useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Scatter } from "react-chartjs-2";

import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

import dayjs from "dayjs";

import LocationGraph from "./MiniGraphs/LocationGraph";
import DecisionGraph from "./MiniGraphs/DecisionGraph";
import DeltaAltitudeGraph from "./MiniGraphs/DeltaAltitudeGraph";
import DeltaVelocityGraph from "./MiniGraphs/DeltaVelocityGraph";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Graph = ({
  locationData,
  selectedObjects,
  mainFocus,
  anomalyList,
  anomalyMode,
}) => {
  const [prevSelected, setPrevSelected] = useState([]);
  const [anomalyNum, setAnomalyNum] = useState(0);
  const [labels, setLabels] = useState([]);
  const [parsedData, setParsedData] = useState({
    altitude: [],
    velocity: [],
    location: [],
    historicalLocation: [],
    deltaVelocity: [],
    deltaAltitude: [],
    decision: [],
    decisionBoundary: 0,
  });
  const [objectLocationData, setObjectLocationData] = useState(null);
  const [callSign, setCallSign] = useState("");

  const defaultParsed = {
    Flights: { altitude: [], velocity: [] },
    Marine: { valocity: [] },
  };

  const updateTick = useRef();

  useEffect(() => {
    if (mainFocus == "Flights") {
      setParsedData({
        altitude: [],
        velocity: [],
        location: [],
        historicalLocation: [],
        deltaVelocity: [],
        deltaAltitude: [],
        decision: [],
        decisionBoundary: 0,
      });
      setObjectLocationData(null);
      setAnomalyNum(selectedObjects.length - 1);
    }
    if (mainFocus == "Marine") {
      setParsedData({
        velocity: [],
        course: [],
        deltaVelocity: [],
      });
      setObjectLocationData(null);
      setAnomalyNum(selectedObjects.length - 1);
    }
  }, [selectedObjects]);

  const updateSelected = (e) => {
    console.log(anomalyNum);
    if (e.target.id == "right") {
      if (anomalyNum < selectedObjects.length - 1) {
        setParsedData(defaultParsed[mainFocus]);
        setAnomalyNum(anomalyNum + 1);
      }
    } else if (e.target.id == "left") {
      if (anomalyNum > 0) {
        setParsedData(defaultParsed[mainFocus]);
        setAnomalyNum(anomalyNum - 1);
      }
    }
  };

  useEffect(() => {
    if (!updateTick.current) {
      updateTick.current = setInterval(() => {
        updateData();
      }, 2000);
    } else {
      clearInterval(updateTick.current);
      updateTick.current = setInterval(() => {
        updateData();
      }, 2000);
    }
  }, [anomalyNum]);

  const updateData = () => {
    if (
      Object.keys(locationData).includes(selectedObjects[anomalyNum]) &&
      selectedObjects[anomalyNum] &&
      locationData[selectedObjects[anomalyNum]] &&
      locationData[selectedObjects[anomalyNum]] != prevSelected
    ) {
      if (mainFocus == "Flights") {
        let newLabels = [];
        let newAltitude = [];
        let newVelocity = [];
        let newLocation = [];
        let newHistoricalLocation = [];
        let newDecision = [];
        let newDecisionBoundary = 0;
        let newDeltaVelocity = [];
        let newDeltaAltitude = [];

        setCallSign(locationData[selectedObjects[anomalyNum]][0].callsign);

        locationData[selectedObjects[anomalyNum]].map((item) => {
          newLabels.push(dayjs.unix(item.rawTime).format("HH:mm:ss"));
          newAltitude.push(item.altitude);
          newVelocity.push(item.velocity);
          newLocation.push({ x: item.long, y: item.lat });
          newDecision = item.decisionStatistic;
          newDeltaVelocity.push(item.velocityDifference);
          newDeltaAltitude.push(item.altitudeDifference);
          newDecisionBoundary = item.anomalyDecisionBoundary;

          item.historicalLat.forEach((location, index) => {
            newHistoricalLocation.push({
              x: item.historicalLong[index],
              y: location,
            });
          });
        });
        setLabels(newLabels);

        setParsedData({
          altitude: newAltitude,
          velocity: newVelocity,
          deltaVelocity: newDeltaVelocity,
          deltaAltitude: newDeltaAltitude,
          decision: newDecision,
          decisionBoundary: newDecisionBoundary,
          historicalLocation: newHistoricalLocation,
        });
        setObjectLocationData(newLocation);

        setPrevSelected(locationData[selectedObjects[anomalyNum]]);
      } else if (mainFocus == "Marine") {
        let newLabels = [];
        let newVelocity = [];
        let newCourse = [];
        let newDeltaVelocity = [];

        setCallSign(locationData[selectedObjects[anomalyNum]][0].callsign);

        locationData[selectedObjects[anomalyNum]].map((item, index) => {
          newLabels.push(dayjs.unix(item.rawTime).format("HH:mm:ss"));
          newVelocity.push(item.velocity);
          newCourse.push(item.course);
          newDeltaVelocity.push(
            locationData[selectedObjects[anomalyNum]][index] -
              locationData[selectedObjects[anomalyNum]][index - 1]
          );
        });
        setLabels(newLabels);
        setParsedData({
          velocity: newVelocity,
          course: newCourse,
          deltaVelocity: newDeltaVelocity,
        });
        setPrevSelected(locationData[selectedObjects[anomalyNum]]);
      }
    }
  };

  const options = {
    Flights: {
      maintainAspectRatio: false,
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          font: {
            weight: "",
            size: 20,
          },
          display: true,
          text: `Data for flight with ICAO: ${selectedObjects[anomalyNum]}, Call Sign: ${callSign} `,
        },
        legend: {
          labels: {
            font: {
              size: 15,
              weight: "bold",
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            font: {
              size: 15,
            },
          },
          type: "linear",
          display: true,
          position: "left",
          title: {
            display: true,
            text: "Altitude (ft)",
            font: {
              weight: "bold",
              size: 20,
            },
          },
        },
        y1: {
          ticks: {
            font: {
              size: 15,
            },
          },
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
          title: {
            fontSize: 16,
            fontStyle: "bold",
            display: true,
            text: "Velocity (kt)",
            font: {
              weight: "bold",
              size: 20,
            },
          },
        },
        x: {
          ticks: {
            font: {
              size: 15,
            },
          },
          title: {
            display: true,
            text: "Time (UTC)",
            font: {
              weight: "bold",
              size: 20,
            },
          },
        },
      },
    },
    Marine: {
      maintainAspectRatio: false,
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          font: {
            weight: "",
            size: 20,
          },
          display: true,
          text: `Data for vessel with MMSI: ${selectedObjects[anomalyNum]}, Call Sign: ${callSign} `,
        },
        legend: {
          labels: {
            font: {
              size: 15,
              weight: "bold",
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            font: {
              size: 15,
            },
          },
          type: "linear",
          display: true,
          position: "left",
          title: {
            display: true,
            text: "Velocity (kt)",
            font: {
              weight: "bold",
              size: 20,
            },
          },
        },
        x: {
          ticks: {
            font: {
              size: 15,
            },
          },
          title: {
            display: true,
            text: "Time (UTC)",
            font: {
              weight: "bold",
              size: 20,
            },
          },
        },
      },
    },
  };

  const data = {
    Flights: {
      labels,
      datasets: [
        {
          label: "Altitude",
          data: parsedData.altitude,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          yAxisID: "y",
        },
        {
          label: "Velocity",
          data: parsedData.velocity,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          yAxisID: "y1",
        },
      ],
    },
    Marine: {
      labels,
      datasets: [
        {
          label: "Velocity",
          data: parsedData.velocity,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          yAxisID: "y",
        },
      ],
    },
  };

  const optionsScatter = {
    maintainAspectRatio: false,
    type: "scatter",
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        font: {
          weight: "bold",
          size: 18,
        },
        display: true,
        text: "Location Data",
      },
      legend: {
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Longitude (deg)",
          font: {
            weight: "bold",
            size: 15,
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Latitude (deg)",
          font: {
            weight: "bold",
            size: 15,
          },
        },
      },
    },
  };

  const FlightGraphs = () => (
    <>
      <div className="flex mt-3 h-[20rem] w-1/2">
        <Scatter
          data={{
            labels: labels,
            datasets: [
              {
                label: "Current Location",
                data: objectLocationData,
                yAxisID: "y",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
              // ...
            ],
          }}
          options={optionsScatter}
        />
        <DecisionGraph
          labels={labels}
          locationData={locationData}
          parsedData={parsedData}
          anomalyNum={anomalyNum}
        />
      </div>

      {/*      <div className="flex mt-3 h-[20rem] w-1/2">
        <DeltaVelocityGraph
          labels={labels}
          locationData={locationData}
          parsedData={parsedData}
          anomalyNum={anomalyNum}
        />
        <DeltaAltitudeGraph
          labels={labels}
          locationData={locationData}
          parsedData={parsedData}
          anomalyNum={anomalyNum}
        />
      </div> */}
    </>
  );

  const MarineGraphs = () => (
    <>
      {/* <div className="flex mt-3 h-[20rem] w-1/2">
        <DeltaVelocityGraph
          labels={labels}
          locationData={locationData}
          parsedData={parsedData}
          anomalyNum={anomalyNum}
        />
      </div> */}
    </>
  );

  return (
    <div className="bg-slate-100 w-full rounded-xl shadow-xl flex flex-col">
      <div className="px-3 py-3 h-[95%]">
        <div className="flex gap-x-5 items-center">
          <h1 className="font-semibold text-2xl">Graphs</h1>
          <div className="flex gap-x-2">
            <AiOutlineArrowLeft
              className="cursor-pointer disabled:cursor-not-allowed disabled:bg-black"
              id="left"
              onClick={updateSelected}
              size={25}
              disabled={anomalyNum == 0}
            />
            <AiOutlineArrowRight
              className="cursor-pointer"
              id="right"
              onClick={updateSelected}
              size={25}
            />
          </div>
          <h1>
            {anomalyNum + 1} / {selectedObjects.length}
          </h1>
        </div>
        <div className="w-fill h-[35rem]">
          <Line options={options[mainFocus]} data={data[mainFocus]} />
        </div>
        {/*   {anomalyMode &&
          Object.keys(anomalyList).includes(selectedObjects[anomalyNum]) &&
          selectedObjects[anomalyNum] &&
          locationData[selectedObjects[anomalyNum]][
            locationData[selectedObjects[anomalyNum]].length - 1
          ].anom && */}
        {mainFocus == "Flights" && (
          <div className="flex mt-3 h-[20rem] w-1/2">
            <Scatter
              data={{
                labels: labels,
                datasets: [
                  {
                    label: "Current Location",
                    data: objectLocationData,
                    yAxisID: "y",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                  },
                  // ...
                ],
              }}
              options={optionsScatter}
            />
            <DecisionGraph
              labels={labels}
              locationData={locationData}
              parsedData={parsedData}
              anomalyNum={anomalyNum}
            />
          </div>
        )}{" "}
        <MarineGraphs />
      </div>
    </div>
  );
};

export default Graph;

///decisionStatisticX and decisionStatisticY gets Iterations this plane was witnessed as X col and Decision Statistic as Y col
//Anomaly decision boundary, constant line across X axis

//Difference of velocity over time

//VelocityDifferenceX
//VelocityDifferenceY
//Altitude DifferenceX
//Altitude DifferenceY

/* flightData['decisionStatisticX'] = [0]
flightData['decisionStatisticY'] = [0]
flightData['velocityDifferenceX'] = [0
flightData['velocityDifferenceY'] = [0]
flightData['altitudeDifferenceX'] = [0]
flightData['altitudeDifferenceY'] = [0]
flightData['anomalyDecisionBoundary'] = 0 */
