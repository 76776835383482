import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

const Hero = () => {
  const navigate = useNavigate();

  const { loginWithRedirect } = useAuth0();

  return (
    <div className="w-full">
      <div className="mt-28 flex w-full justify-center">
        <div className="flex flex-col items-center w-[80%] 2xl:w-[60%]">
          <h1 className="text-center font-extrabold md:text-6xl 2xl:text-6xl drop-shadow-lg">
            Multi-Domain Real-Time Anomaly Detection Using Explainable
            AI-Enabled Algorithms
          </h1>
        </div>
      </div>
      <div className="mt-32">
        <div className="mt-28 flex w-full justify-center">
          <div className="flex justify-center w-[53%] 2xl:w-[28%]">
            <button
              onClick={() => {
                loginWithRedirect();
              }}
              className="p-3 px-16 bg-blue-700 hover:bg-blue-600 rounded-lg"
            >
              <p className="text-xl text-white font-bold">Get Started {">"}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
