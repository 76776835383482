const getEpochMillis = function (dateStr) {
  var r = /^\s*(\d{4})-(\d\d)-(\d\d)\s+(\d\d):(\d\d):(\d\d)\s+UTC\s*$/,
    m = ("" + dateStr).match(r);
  var x = m ? Date.UTC(m[1], m[2] - 1, m[3], m[4], m[5], m[6]) : undefined;
  x = x.toString();
  x = x.slice(0, -3);
  x = parseInt(x);
  return x;
};

module.exports = {
  getEpochMillis,
};
