import React, { useState, useEffect } from "react";

import AlertsBox from "./Alerts/AlertsBox";
import Map from "./Map";
import Options from "./Options";
import Graph from "./Graph";

import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../Assets/Logo1.png";
import {
  serverLocationOptions,
  mainFocusOptions,
  aorOptions,
  aorCoordinates,
} from "../../Lib/constants";

import { AiOutlineLogout } from "react-icons/ai";

const Home = () => {
  const { isAuthenticated, user, logout } = useAuth0();

  const [locationData, setLocationData] = useState({});
  const [locationDataCount, setLocationDataCount] = useState(0);

  const [mainFocus, setMainFocus] = useState("Flights");
  const [interval, setInterval] = useState(1);
  const [anomalyMode, setAnomalyMode] = useState(
    localStorage.getItem("anomaly") != null
      ? JSON.parse(localStorage.getItem("anomaly"))
      : false
  );
  const [anomalyList, setAnomalyList] = useState({});
  const [aor, setAOR] = useState(aorOptions[mainFocusOptions[0]][0].name);
  const [keepConnection, setKeepConnection] = useState(false);
  const [weatherToggle, setWeatherToggle] = useState(false);
  const [historyToggle, setHistoryToggle] = useState(false);
  const [manualCoors, setManualCoors] = useState(aorCoordinates["Flights"]);

  const [anomalyStrength, setAnomalyStrength] = useState(0);
  const [serverLocation, setServerLocation] = useState(
    serverLocationOptions[mainFocusOptions[0]][0]
  );
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [resetZoom, setResetZoom] = useState(0);

  const [verified, setVerified] = useState(null);

  useEffect(() => {
    if (user) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/auth0/roles`, {
        method: "POST",
        body: JSON.stringify({ user_id: user.sub }),
      }).then((res) => {
        res.json().then((data) => {
          setVerified(false);
          console.log(data);
          if (data[0].id == "rol_7e6WaqZDJK36HaJp") {
            setVerified(true);
          }
        });
      });
    }
  }, [user]);

  if (!verified) {
    return (
      <div>
        <div className="w-full flex flex-row justify-between items-center py-4 pr-5 pl-2">
          <img src={logo} className="md:w-28 w-3 ml-5" />
          <h1 className="font-bold text-3xl text-center">
            Multi-Domain Real-Time Anomaly Detection Using{" "}
            <span className="block">Explainable AI-Enabled Algorithms</span>
          </h1>
          <div
            className="flex gap-x-2 hover:cursor-pointer"
            onClick={() =>
              logout({
                returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
              })
            }
          >
            <h1 className="self-center text-lg font-semibold">Logout</h1>
            <AiOutlineLogout className="self-center text-3xl cursor-pointer" />
          </div>
        </div>

        <div className="w-full flex flex-row content-between">
          <div className="2xl:w-[75rem] xl:w-[50rem] md:w-[40rem] w-[30rem] pl-5 flex flex-col gap-y-2">
            <Map
              mainFocus={mainFocus}
              anomalyMode={anomalyMode}
              interval={interval}
              aor={aor}
              setAOR={setAOR}
              setAnomalyList={setAnomalyList}
              anomalyList={anomalyList}
              weatherToggle={weatherToggle}
              manualCoors={manualCoors}
              resetZoom={resetZoom}
              serverLocation={serverLocation}
              anomalyStrength={anomalyStrength}
              selectedObjects={selectedObjects}
              setSelectedObjects={setSelectedObjects}
              locationData={locationData}
              setLocationData={setLocationData}
              setLocationDataCount={setLocationDataCount}
              historyToggle={historyToggle}
            />
            {selectedObjects.length && mainFocus != "Space" && (
              <Graph
                locationData={locationData}
                selectedObjects={selectedObjects}
                mainFocus={mainFocus}
                anomalyList={anomalyList}
                anomalyMode={anomalyMode}
                historyToggle={historyToggle}
              />
            )}
          </div>
          <div className="flex flex-col w-full pl-5 max-h-[50rem]">
            <Options
              mainFocus={mainFocus}
              setMainFocus={setMainFocus}
              interval={interval}
              setInterval={setInterval}
              anomalyMode={anomalyMode}
              setAnomalyMode={setAnomalyMode}
              aor={aor}
              setAOR={setAOR}
              keepConnection={keepConnection}
              setKeepConnection={setKeepConnection}
              weatherToggle={weatherToggle}
              setWeatherToggle={setWeatherToggle}
              manualCoors={manualCoors}
              setManualCoors={setManualCoors}
              anomalyStrength={anomalyStrength}
              setAnomalyStrength={setAnomalyStrength}
              setResetZoom={setResetZoom}
              setServerLocation={setServerLocation}
              setSelectedObjects={setSelectedObjects}
              serverLocation={serverLocation}
              historyToggle={historyToggle}
              setHistoryToggle={setHistoryToggle}
            />

            <AlertsBox
              mainFocus={mainFocus}
              anomalyMode={anomalyMode}
              anomalyList={anomalyList}
              selectedObjects={selectedObjects}
              setSelectedObjects={setSelectedObjects}
              locationData={locationData}
              locationDataCount={locationDataCount}
            />
          </div>
        </div>
      </div>
    );
  } else if (verified == false) {
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <div className="flex flex-col items-center">
          <h1 className="text-5xl font-bold">
            Your account is pending verification.
          </h1>

          <button
            onClick={() =>
              logout({
                returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
              })
            }
            className="px-3 py-2 bg-blue-500 mt-3 text-2xl text-white rounded-lg"
          >
            Logout
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <div className="flex flex-col items-center">
          <h1 className="text-5xl font-bold">Loading...</h1>
        </div>
      </div>
    );
  }
};

export default Home;
