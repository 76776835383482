import React, { useState, useRef, useMemo } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const LocationGraph = ({ location, labels }) => {
  console.log(location, "jfdkshasdjfhklakkkakakakakakakakakakaa");

  const locationRef = useRef(null);

  if (locationRef.current) {
    if (
      locationRef.current[locationRef.current.length - 1] !=
      location[location.length - 1]
    ) {
      locationRef.current = location;
    }
  } else {
    locationRef.current = location;
  }

  /* 
  console.warn(
    locationRef.current[locationRef.current.length - 1],
    location[location.length - 1],
    "locationRef.current"
  ); */

  const options = {
    maintainAspectRatio: false,
    type: "scatter",
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        font: {
          weight: "bold",
          size: 18,
        },
        display: true,
        text: "Location Data",
      },
      legend: {
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Longitude (deg)",
          font: {
            weight: "bold",
            size: 15,
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Latitude (deg)",
          font: {
            weight: "bold",
            size: 15,
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Current Location",
        data: location,
        yAxisID: "y",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      // ...
    ],
  };

  return (
    <Scatter
      data={{
        labels: labels,
        datasets: [
          {
            label: "Current Location",
            data: locationRef,
            yAxisID: "y",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          // ...
        ],
      }}
      options={options}
    />
  );
};

export default LocationGraph;
