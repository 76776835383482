const mainFocusOptions = [
  "Flights",
  "Marine",
  "Ground",
  "Space",
  "Air + Sea",
  "All",
];
const mainFocusText = {
  Flights: "Air",
  Marine: "Sea",
  Ground: "Land",
  Space: "Space",
  "Air + Sea": "Air + Sea",
  All: "All",
};

const aorOptions = {
  Flights: [
    { name: "BWI", disabled: true },
    { name: "DCA", disabled: true },
    { name: "KIAD", disabled: false },
    { name: "Bounding Box Coordinates", disabled: false },
  ],
  Marine: [
    { name: "Bounding Box Coordinates", disabled: false },
    { name: "Port of Baltimore", disabled: true },
  ],
  Space: [
    { name: "Bounding Box Coordinates", disabled: false },
    { name: "Port of Baltimore", disabled: true },
  ],
};

const idenfitifers = {
  Flights: "icao",
  Marine: "mmsi",
  Space: "object_id",
};

const names = {
  Flights: "flights",
  Marine: "ships",
  Space: "space objects",
};
//36.481514, 10.249554

const aorCoordinates = {
  Flights: {
    leftLat: 39.7,
    leftLon: -78.4,
    rightLat: 38.2,
    rightLon: -76.5,
  },
  Marine: {
    leftLat: 40.25,
    leftLon: -74.35,
    rightLat: 40.65,
    rightLon: -73.53,
  },
  Space: {
    leftLat: 36.4,
    leftLon: 10.1,
    rightLat: 36.6,
    rightLon: 10.4,
  },
};

const zoomConstants = {
  Flights: 9,
  Marine: 11,
  Space: 2,
};

const serverLocationOptions = {
  Flights: [
    "https://awsbackend.tetravigil.com",
    "https://awsbackend.aiforsocom.com",
    "https://awsbackend.aiformissiledefense.com",
    "http://70.106.226.102:8080",
    "Manual",
  ],
  Marine: [
    "https://awsbackend.tetravigil.com",
    "https://awsbackend.aiforsocom.com",
    "https://awsbackend.aiformissiledefense.com",
    "http://70.106.226.102:8080",
    "http://localhost:8080",
    "Manual",
  ],
  Space: [
    "https://awsbackend.tetravigil.com",
    "https://awsbackend.aiforsocom.com",
    "https://awsbackend.aiformissiledefense.com",
    "http://70.106.226.102:8080",
    "http://localhost:8080",
    "Manual",
  ],
};

const defaultRefreshTime = {
  Flights: 1,
  Marine: 5,
  Space: 5,
};

module.exports = {
  mainFocusOptions,
  aorOptions,
  names,
  aorCoordinates,
  serverLocationOptions,
  defaultRefreshTime,
  idenfitifers,
  zoomConstants,
  mainFocusText,
};
