import React from "react";

import dayjs from "dayjs";
import { idenfitifers } from "../../../Lib/constants";

const Alert = ({
  time,
  type,
  identifier,
  callsign,
  attributeValue,
  selected,
  attributeTrainingValue,
  setSelectedObjects,
  selectedObjects,
  mainFocus,
}) => {
  return (
    <div
      onClick={() => {
        console.log(identifier, selectedObjects, selected);
        if (selectedObjects.includes(identifier)) {
          setSelectedObjects(
            selectedObjects.filter((flight) => flight !== identifier)
          );
        } else {
          setSelectedObjects((prev) => {
            return [...prev, identifier];
          });
        }
      }}
      className={
        selected
          ? "hover:cursor-pointer min-h-14 bg-red-400 border-8 border-blue-500 mb-3"
          : "hover:cursor-pointer min-h-14 bg-red-400 mb-3"
      }
    >
      <div
        style={{ fontFamily: "Monospace" }}
        className="w-full h-full flex flex-row "
      >
        <div className="w-[15%] h-full">
          <h1 className="mt-2 ml-3">{dayjs.unix(time).format("h:mm A")}</h1>
        </div>
        <div className="bg-black h-full w-[0.15rem]"></div>
        <div className="w-[80%] h-full flex items-center pl-4 py-1 ">
          <div>
            <h1 className="text-lg font-semibold">{type} Detected</h1>
            <div>
              <span className="font-bold">
                {idenfitifers[mainFocus].toUpperCase()}:{" "}
              </span>
              {identifier}, <span className="font-bold">CALL SIGN: </span>
              {callsign},{" "}
              {attributeValue && type == "Altitude Anomaly" && (
                <h1>
                  <span className="font-bold">ACTUAL ALTITUDE (ft):</span>{" "}
                  {attributeValue},{" "}
                  <span className="font-bold">EXPECTED ALTITUDE (ft): </span>
                  {attributeTrainingValue}
                </h1>
              )}
              {attributeValue && type == "Velocity Anomaly" && (
                <h1>
                  <span className="font-bold">ACTUAL VELOCITY (kt):</span>{" "}
                  {attributeValue},{" "}
                  <span className="font-bold">EXPECTED VELOCITY (kt): </span>
                  {attributeTrainingValue}
                </h1>
              )}
              {attributeValue && type == "Position Anomaly" && (
                <h1>
                  <span className="font-bold">ACTUAL POSITION (lat, lon):</span>{" "}
                  {attributeValue},{" "}
                  <span className="font-bold">
                    EXPECTED POSITION (lat, lon):{" "}
                  </span>
                  {attributeTrainingValue}
                </h1>
              )}
              {attributeValue && type == "Anomaly in Position" && (
                <h1>
                  <span className="font-bold">ACTUAL POSITION (lat, lon):</span>{" "}
                  {attributeValue},{" "}
                  <span className="font-bold">
                    EXPECTED POSITION (lat, lon):{" "}
                  </span>
                  {attributeTrainingValue}
                </h1>
              )}
              {attributeValue && type == "Anomaly in Speed on Ground" && (
                <h1>
                  <span className="font-bold">ACTUAL SPEED (kt):</span>{" "}
                  {attributeValue},{" "}
                  <span className="font-bold">EXPECTED SPEED (kt): </span>
                  {attributeTrainingValue}
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
