import React, { useEffect, useState, useRef } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-range-slider-input/dist/style.css";
import "../../slider.css";

import Switch from "react-switch";
import RangeSlider from "react-range-slider-input";

import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import { CgDanger } from "react-icons/cg";

import {
  aorCoordinates,
  aorOptions,
  mainFocusOptions,
  serverLocationOptions,
  defaultRefreshTime,
  mainFocusText,
} from "../../Lib/constants";

const Options = ({
  mainFocus,
  setMainFocus,
  aor,
  setAOR,
  interval,
  setInterval,
  anomalyMode,
  setAnomalyMode,
  keepConnection,
  setKeepConnection,
  weatherToggle,
  setWeatherToggle,
  manualCoors,
  setManualCoors,
  anomalyStrength,
  setAnomalyStrength,
  setResetZoom,
  setServerLocation,
  setSelectedObjects,
  serverLocation,
  historyToggle,
  setHistoryToggle,
}) => {
  const serverLocationInput = useRef(null);

  const [hidden, setHidden] = useState(false);
  const [aorCopy, setAORCopy] = useState(aor);
  const [serverLocationText, setServerLocationText] = useState(serverLocation);

  const handleMainFocusChange = (e) => {
    setMainFocus(e.target.value);
  };
  const handleAORChange = (e) => {
    if (e.target.value == "KIAD") {
      setManualCoors(aorCoordinates.Flights);
    }
    setAOR(e.target.value);
    setAORCopy(e.target.value);

    if (e.target.value != "Bounding Box Coordinates") {
      setAOR("KIAD");
    } else {
      setAOR(false);
    }
  };

  const handleManualCoordChange = (e) => {
    if (e.target.value != "-" && e.target.value != "") {
      setManualCoors({
        ...manualCoors,
        [e.target.id]: parseFloat(e.target.value),
      });
      setAOR(false);
    }
  };

  const resetZoom = () => {
    setResetZoom((prev) => prev + 1);
    setSelectedObjects([]);
    setManualCoors(aorCoordinates[mainFocus]);
  };

  useEffect(() => {
    setAOR(aorOptions[mainFocus].find((aor) => !aor.disabled).name);
    setAORCopy(aorOptions[mainFocus].find((aor) => !aor.disabled).name);
    setServerLocation(serverLocationOptions[mainFocus][0]);
    setServerLocationText(serverLocationOptions[mainFocus][0]);
    setInterval(defaultRefreshTime[mainFocus]);
    setManualCoors(aorCoordinates[mainFocus]);
  }, [mainFocus]);

  return (
    <div className="bg-slate-100 w-[95%] rounded-xl shadow-xl">
      <div className="px-3 py-3 h-[95%]">
        <div className="w-full flex justify-between ">
          <div class="flex gap-x-2">
            <h1 className="font-bold text-2xl">Options</h1>
            <div
              class="hover:cursor-pointer"
              onClick={() => {
                setHidden((prev) => !prev);
              }}
            >
              {hidden ? (
                <FiChevronRight size={32} />
              ) : (
                <FiChevronDown size={32} />
              )}
            </div>
          </div>

          <div className="flex gap-x-2">
            <button
              className="font-semibold bg-blue-600 px-2 py-1 rounded-xl text-white"
              onClick={resetZoom}
            >
              Reset Map
            </button>
            <button
              className="font-semibold bg-blue-600 px-2 py-1 rounded-xl text-white"
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh Page
            </button>
          </div>
        </div>
        <div
          className={`flex flex-col justify-around h-full gap-y-3 ${
            hidden && "hidden"
          }`}
        >
          <div>
            <h1 className="text-md font-semibold mt-1">
              Domain(s) of Interest
            </h1>
            <select
              className="w-full h-8 mt-1"
              value={mainFocus}
              onChange={handleMainFocusChange}
            >
              {mainFocusOptions.map((option) => (
                <option
                  key={option}
                  value={option}
                  disabled={
                    option == "Ground" ||
                    option == "All" ||
                    option == "Air + Sea"
                  }
                >
                  {mainFocusText[option]}
                </option>
              ))}
            </select>
            {mainFocus == "Marine" && (
              <h1 className="text-red-400 flex gap-x-2 items-center">
                <CgDanger /> Vessel data may take a minute to initially load.
              </h1>
            )}
            <h1 className="text-md font-semibold mt-3">Server Location</h1>
            <div className="flex">
              <select
                ref={serverLocationInput}
                value={serverLocationText}
                className="w-full h-8 mt-1"
                placeholder="Server Location"
                onChange={(e) => {
                  setServerLocation(e.target.value);
                  setServerLocationText(e.target.value);
                }}
              >
                {serverLocationOptions[mainFocus].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            {serverLocation == "http://70.106.242.196:8080" && (
              <h1 className="text-red-400 flex gap-x-2 items-center">
                <CgDanger /> This location may not always be active. Check with
                the site administrator.
              </h1>
            )}
            {serverLocationText == "Manual" ? (
              <div className="flex flex-col mt-2">
                <label className="text-md font-semibold">Server Location</label>
                <input
                  onChange={(e) => {
                    setServerLocation(e.target.value);
                  }}
                  className="h-8 bg-slate-200"
                  placeholder="Server Location"
                />
              </div>
            ) : null}

            <h1 className="text-md font-semibold mt-3">
              Area of Responsibility (AOR)
            </h1>
            <select
              value={aorCopy}
              onChange={handleAORChange}
              className="w-full h-8 mt-1"
            >
              {aorOptions[mainFocus].map((option) => (
                <option
                  key={option.name}
                  value={option.name}
                  disabled={option.disabled}
                >
                  {option.name}
                </option>
              ))}
            </select>
            {aorCopy == "Bounding Box Coordinates" ? (
              <div className="flex flex-row  justify-between gap-x-2 mt-2">
                <div className="flex flex-col">
                  <label className="text-md font-semibold">
                    Top Left Corner
                  </label>
                  <div className="flex gap-x-1">
                    <input
                      id="leftLat"
                      onChange={handleManualCoordChange}
                      value={manualCoors.leftLat}
                      className="w-1/2 h-8 bg-slate-200"
                      placeholder="Latitude"
                    />
                    <input
                      id="leftLon"
                      onChange={handleManualCoordChange}
                      value={manualCoors.leftLon}
                      className="w-1/2 h-8 bg-slate-200"
                      placeholder="Longitude"
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="text-md font-semibold">
                    Bottom Right Corner
                  </label>
                  <div className="flex gap-x-1">
                    <input
                      id="rightLat"
                      onChange={handleManualCoordChange}
                      value={manualCoors.rightLat}
                      className="w-1/2 h-8 bg-slate-200"
                      placeholder="Latitude"
                    />
                    <input
                      id="rightLon"
                      onChange={handleManualCoordChange}
                      value={manualCoors.rightLon}
                      className="w-1/2 h-8 bg-slate-200"
                      placeholder="Longitude"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {/*    <div className="mt-3">
              <h1>Keep connection:</h1>
              <Switch
                className="mt-1"
                checked={keepConnection}
                onChange={() => {
                  setKeepConnection(!keepConnection);
                }}
              />
            </div> */}
          </div>
          <div>
            <h1 className="text-md font-semibold ">
              Frequency of Data Update(s)
            </h1>
            <input
              className="bg-slate-200 mt-1 w-full disabled:text-gray-400"
              placeholder="Seconds"
              type="number"
              value={interval}
              disabled={mainFocus == "Marine"}
              onChange={({ target }) => {
                if (target.value > 0) {
                  setInterval(target.value);
                }
              }}
            />
          </div>
          <h1 className="text-md font-semibold ">Anomaly Detection</h1>
          <div className="flex gap-x-5">
            <div>
              <h1>Show Anomalies</h1>
              <Switch
                className="mt-1"
                checked={anomalyMode}
                onChange={() => {
                  localStorage.setItem("anomaly", !anomalyMode);
                  setAnomalyMode(!anomalyMode);
                }}
              />
            </div>
            <div>
              <h1>Show Historical Data</h1>
              <Switch
                className="mt-1"
                checked={historyToggle}
                onChange={() => {
                  setHistoryToggle(!historyToggle);
                }}
              />
            </div>
          </div>
          <h1 className="text-md mb-[-12px]">Anomaly Detection Threshold</h1>
          <div className="flex w-full items-center justify-center ">
            <h1 className="text-xl mr-2">{anomalyStrength}</h1>
            <RangeSlider
              onInput={(e) => {
                setAnomalyStrength(e[1]);
              }}
              className="single-thumb "
              defaultValue={[0, 0]}
              min={0}
              max={10}
              step={1}
              thumbsDisabled={[true, false]}
              rangeSlideDisabled={true}
            />
          </div>
          <div className="flex w-full items-center justify-between mt-[-10px] ml-4">
            <h1>Low</h1>
            <h1>Medium</h1>
            <h1>High</h1>
          </div>
          {/*   <div>
            <h1>Show Weather:</h1>
            <Switch
              className="mt-1"
              checked={weatherToggle}
              onChange={() => {
                setWeatherToggle(!weatherToggle);
              }}
            />
          </div> */}

          {/*          <label
            for="minmax-range"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Min-max range
          </label>
          <input
            id="minmax-range"
            type="range"
            min="0"
            max="10"
            value="5"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          />
 */}
        </div>
      </div>
    </div>
  );
};

export default Options;
