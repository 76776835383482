import { useEffect, useRef } from "react";
import L from "leaflet";

import Plane from "../../Assets/Plane.png";
import AnomPlane from "../../Assets/AnomalyPlane.png";

import AnomShip from "../../Assets/Ships/AnomalyShip.png";
import SelectedShip from "../../Assets/Ships/SelectedShip.png";
import Ship from "../../Assets/Ships/Ship.png";

import Satellite from "../../Assets/Space/Satellite.png";

function Legend({ map, mainFocus }) {
  const legendRef = useRef(null);

  useEffect(() => {
    if (legendRef.current) {
      legendRef.current.remove();
    }

    if (mainFocus == "Flights") {
      if (map) {
        const legend = L.control({ position: "topright" });

        legend.onAdd = () => {
          const div = L.DomUtil.create(
            "div",
            "p-3 text-align-right bg-white opacity-75 rounded-xl flex flex-col"
          );
          div.innerHTML =
            "<h1 class='text-xl font-semibold text-right'>Legend</h1>" +
            "<div class='flex items-center'>" +
            `<img class='w-6 mr-2' src=${Plane} alt='Nominal Flight Icon' />` +
            "<h1 class='font-medium'>Nominal Flight</h1>" +
            "</div>" +
            "<div class='flex items-center mb-1'>" +
            `<img class='w-6 mr-2' src=${AnomPlane} alt='Anomaly Flight Icon' />` +
            "<h1 class='font-medium'>Anomalous Flight</h1>" +
            "</div>" +
            "<div class='flex items-center mb-2'>" +
            "<div class='w-4 h-1 ml-1 mr-3 bg-[#0000ff] rounded-lg'></div>" +
            "<h1 class='font-medium'>Nominal Flight Path</h1>" +
            "</div>" +
            "<div class='flex items-center mb-2'>" +
            "<div class='w-1 h-1 ml-1 bg-[#008000] rounded-full inline-block'></div>" +
            "<div class='w-1 h-1 ml-1 bg-[#008000] rounded-full inline-block'></div>" +
            "<div class='w-1 h-1 ml-1 mr-2 bg-[#008000] rounded-full inline-block'></div>" +
            "<h1 class='font-medium'>Historical Flight Positions</h1>" +
            "</div>" +
            "<div class='flex items-center mb-2'>" +
            "<div class='w-4 h-1 ml-1 mr-3 bg-[#f20000] rounded-lg'></div>" +
            "<h1 class='font-medium'>Anomalous Flight Path</h1>" +
            "</div>";
          return div;
        };

        legend.addTo(map);
        legendRef.current = legend;
      }
    } else if (mainFocus == "Marine") {
      const legend = L.control({ position: "topright" });

      legend.onAdd = () => {
        const div = L.DomUtil.create(
          "div",
          "p-3 text-align-right bg-white opacity-75 rounded-xl flex flex-col"
        );
        div.innerHTML =
          "<h1 class='text-xl font-semibold text-right'>Legend</h1>" +
          "<div class='flex items-center'>" +
          `<img class='w-6 mr-2' src=${Ship} alt='Nominal Vessel Icon' />` +
          "<h1 class='font-medium'>Nominal Vessel</h1>" +
          "</div>" +
          "<div class='flex items-center'>" +
          `<img class='w-6 mr-2' src=${AnomShip} alt='Anomaly Vessel Icon' />` +
          "<h1 class='font-medium'>Anomalous Vessel</h1>" +
          "</div>" +
          "<div class='flex items-center mb-1'>" +
          `<img class='w-6 mr-2' src=${SelectedShip} alt='Selected Vessel Icon' />` +
          "<h1 class='font-medium'>Selected Nominal/Anomalous Vessel</h1>" +
          "</div>" +
          "<div class='flex items-center mb-2'>" +
          "<div class='w-4 h-1 ml-1 mr-3 bg-black rounded-lg'></div>" +
          "<h1 class='font-medium'>Nominal Vessel Path</h1>" +
          "</div>" +
          "<div class='flex items-center mb-2'>" +
          "<div class='w-4 h-1 ml-1 mr-3 bg-[#f20000] rounded-lg'></div>" +
          "<h1 class='font-medium'>Anomalous Vessel Path</h1>" +
          "</div>" +
          "<div class='flex items-center mb-2'>" +
          "<div class='w-1 h-1 ml-1 bg-[#008000] rounded-full inline-block'></div>" +
          "<div class='w-1 h-1 ml-1 bg-[#008000] rounded-full inline-block'></div>" +
          "<div class='w-1 h-1 ml-1 mr-2 bg-[#008000] rounded-full inline-block'></div>" +
          "<h1 class='font-medium'>Historical Marine Positions</h1>" +
          "</div>";
        return div;
      };

      legend.addTo(map);
      legendRef.current = legend;
    }
  }, [map, mainFocus]);
  return null;
}

export default Legend;
