import React from "react";

import Navbar from "./Navbar";
import Hero from "./Hero";

const Landing = () => {
  return (
    <div className="z-10 bg-gradient-to-b bg-white">
      <Navbar />
      <Hero />
    </div>
  );
};

export default Landing;
