import React, { useEffect, useState } from "react";

import Alert from "./Alert";

import { idenfitifers, names } from "../../../Lib/constants";
import MLPdf from "../../../Assets/ERIMachineLearningProcessOverview.pdf";

import { AiOutlineDownload } from "react-icons/ai";

const AlertsBox = ({
  anomalyMode,
  anomalyList,
  selectedObjects,
  setSelectedObjects,
  mainFocus,
  locationDataCount,
}) => {
  return (
    <div
      style={{ visibility: !anomalyMode && "hidden" }}
      className="bg-slate-100 mt-3 w-[95%] h-full rounded-xl shadow-xl"
    >
      <div className="px-3 pt-3 h-[98%] flex flex-col">
        <div className="flex justify-between gap-x-7">
          <h1 className="font-semibold text-2xl">Alerts</h1>
          <div>
            {mainFocus == "Flights" && (
              <a href={MLPdf} target="_blank">
                <button
                  className="font-semibold bg-blue-600 px-2 py-1 rounded-xl text-white flex items-center gap-x-2"
                  /* onClick={resetZoom} */
                >
                  <AiOutlineDownload /> ML Training Overview
                </button>
              </a>
            )}
          </div>
        </div>
        <h1 className="text-lg">
          The {names[mainFocus]} listed below and also in{" "}
          <span className="text-red-600">RED</span> on the map are anomalous.{" "}
          <span>
            The total number of anomalies from among {locationDataCount}{" "}
            currently active {names[mainFocus]} is{" "}
            {Object.keys(anomalyList).length || 0}.
          </span>
        </h1>
        <div style={{ overflowY: "scroll" }} className="pt-3 h-full">
          {Object.keys(anomalyList).map((key) => (
            <Alert
              setSelectedObjects={setSelectedObjects}
              selectedObjects={selectedObjects}
              selected={selectedObjects.includes(
                anomalyList[key][idenfitifers[mainFocus]]
              )}
              key={key}
              time={anomalyList[key].time}
              identifier={anomalyList[key][idenfitifers[mainFocus]]}
              callsign={anomalyList[key].callsign}
              type={anomalyList[key].anomalyType}
              attributeValue={anomalyList[key].anomalyAttributeValue}
              attributeTrainingValue={
                anomalyList[key].anomalyAttributeTrainingValue
              }
              mainFocus={mainFocus}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlertsBox;
