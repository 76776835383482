import React, { useEffect, useRef, useState } from "react";

import { generateIcon } from "./GenerateIcon";
import Legend from "./Legend";
import RotatedMarker from "./RotatedMarker";
import usePrevious from "../../Hooks/usePrevious";
import { getEpochMillis } from "../../Lib/utils";

import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as satPre from "satellite.js";

import MapBox, {
  Marker as MapBoxMarker,
  Popup as MapBoxPopup,
  Source as MapBoxSource,
  Layer as MapBoxLayer,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  Rectangle,
  TileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";
import { zoomConstants, aorCoordinates } from "../../Lib/constants";

const Recenter = ({ manualCor, resetZoom, mainFocus }) => {
  const map = useMap();
  useEffect(() => {
    map.setZoom(30);
    map.setView([
      (manualCor.leftLat + manualCor.rightLat) / 2,
      (manualCor.leftLon + manualCor.rightLon) / 2,
    ]);
    map.setZoom(zoomConstants[mainFocus]);
  }, [manualCor, resetZoom]);
  return null;
};

const Map = ({
  mainFocus,
  interval,
  anomalyMode,
  aor,
  setAnomalyList,
  anomalyList,
  weatherToggle,
  manualCoors,
  resetZoom,
  serverLocation,
  anomalyStrength,
  selectedObjects,
  setSelectedObjects,
  locationData,
  setLocationData,
  setLocationDataCount,
  historyToggle,
}) => {
  /* mapboxgl.accessToken =
    "pk.eyJ1IjoiZ2FtZXN0dWRpbyIsImEiOiJjbG5qcmR1Z2QxMTB1MmxvN3pmeG1lYzI3In0.HMKZEQWgaB_J-otxHdnbvA";

  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    width: "100vw",
    height: "100vh",
    zoom: 10,
  });

  const mapGlobeContainer = useRef(null);
  const RealMap = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (RealMap.current) return; // initialize RealMap only once
    RealMap.current = new mapboxgl.Map({
      container: mapGlobeContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });
    const el = document.createElement("div");
    el.style.backgroundImage = require("../../Assets/Space/Satellite.png");
    el.style.width = "50px";
    el.style.height = "50px";
    el.style.backgroundSize = "100%";
    const marker1 = new mapboxgl.Marker(el)
      .setLngLat([-70.9, 42.35])
      .addTo(RealMap.current);
  }); */

  const accessToken =
    "pk.eyJ1IjoiZ2FtZXN0dWRpbyIsImEiOiJjbG5qcmR1Z2QxMTB1MmxvN3pmeG1lYzI3In0.HMKZEQWgaB_J-otxHdnbvA";

  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    width: "100vw",
    height: "100vh",
    zoom: 10,
  });

  const [map, setMap] = useState(null);

  const [objects, setObjects] = useState([]);

  const [marineLocationData, setMarineLocationData] = useState({});
  const [flightLocationData, setFlightLocationData] = useState({});
  const [spaceLocationData, setSpaceLocationData] = useState({});

  const [cleanRound, setCleanRound] = useState(0);

  let rawLocationData = useRef({ flights: [], marine: [], space: [] });
  const prevFocus = usePrevious(mainFocus);

  const marineTick = useRef({ uiUpdater: null, dataFetcher: null });
  const flightTick = useRef();
  const spaceTick = useRef({ uiUpdater: null, dataFetcher: null });

  const flightSSE = useRef();

  dayjs.extend(utc);
  const clearData = () => {
    console.warn(prevFocus, mainFocus);
    if (prevFocus != mainFocus) {
      setObjects([]);
      setLocationData({});
      setAnomalyList({});
      setSelectedObjects([]);
      if (flightSSE.current) {
        flightSSE.current.close();
        localStorage.setItem("anomaly", anomalyMode);
        localStorage.setItem("aor", aor);
      }

      if (prevFocus == "Flights") {
        console.log("Clearing Flights");
        setFlightLocationData({});
        rawLocationData.current.flights = [];
        clearInterval(flightTick.current);
        if (flightSSE.current) {
          flightSSE.current.close();
        }
      }

      if (prevFocus == "Marine") {
        console.log("Clearing Marine");
        setMarineLocationData({});
        rawLocationData.current.marine = [];
        clearInterval(marineTick.current.uiUpdater);
        clearInterval(marineTick.current.dataFetcher);
      }

      if (prevFocus == "Space") {
        console.log("Clearing Space");
        setSpaceLocationData({});
        rawLocationData.current.space = [];
        clearInterval(spaceTick.current.uiUpdater);
        clearInterval(spaceTick.current.dataFetcher);
      }
    }
  };

  useEffect(() => {
    if (interval && mainFocus === "Flights") {
      console.log("FLIGHTS STARTING");
      genFlightLocations();
      clearInterval(flightTick.current);

      flightTick.current = setInterval(
        () => genFlightLocations(),
        interval * 1000
      );
    } else if (interval && mainFocus === "Marine") {
      console.log("MARINE STARTING");
      clearInterval(marineTick.current.uiUpdater);

      marineTick.current.uiUpdater = setInterval(
        () => genMarineLocations(),
        interval * 1000
      );
    } else if (interval && mainFocus === "Space") {
      console.log("SPACE STARTING");
      clearInterval(spaceTick.current.uiUpdater);

      spaceTick.current.uiUpdater = setInterval(
        () => genSpaceLocations(),
        interval * 1000
      );
    }
  }, [interval, mainFocus]);

  useEffect(() => {
    objects.map((key) => {
      console.warn("Cleaning");
      const curr = parseInt(Math.floor(Date.now() / 1000));
      const item = locationData[key][locationData[key].length - 1];

      if (curr - item.rawTime > 300) {
        const oldLoc = locationData;
        delete oldLoc[key];
        //setLocationData(oldLoc);

        console.log(item.rawTime, curr, "DELETED");

        setObjects((prev) => prev.filter((item) => item !== key));
        setSelectedObjects((prev) => prev.filter((item) => item !== key));

        let anomalyListCopy = anomalyList;
        if (key in Object.keys(anomalyList)) {
          console.log("Deleting Anomaly");
          delete anomalyListCopy[key];
          setAnomalyList(anomalyListCopy);
        }
      }
    });

    Object.keys(anomalyList).map((anomKey) => {
      if (!objects.includes(anomKey)) {
        setObjects((prev) => prev.filter((item) => item !== anomKey));
        let anomalyListCopy = anomalyList;
        console.log("Deleting Anomaly");
        delete anomalyListCopy[anomKey];
        setAnomalyList(anomalyListCopy);
      }
    });

    setTimeout(() => {
      setCleanRound((prev) => prev + 1);
    }, 15000);
  }, [cleanRound]);

  const genFlightLocations = () => {
    const parsedLocationData = flightLocationData;
    console.log(rawLocationData, "rawLocationData");

    rawLocationData.current.flights.map((location) => {
      const tempweather = { data: [{ temperature: { fahrenheit: 0 } }] };
      parsedLocationData[location.icao] =
        parsedLocationData[location.icao] || [];
      parsedLocationData[location.icao].push({
        lat: location.latitude,
        long: location.longitude,
        head: location.heading,
        anom: location.anomaly,
        weather: tempweather,
        callsign: location.callsign,
        velocity: location.velocity,
        altitude: location.altitude,
        decisionStatistic: location.decisionStatistic,
        anomalyDecisionBoundary: location.anomalyDecisionBoundary,
        velocityDifference: location.velocityDifference,
        altitudeDifference: location.altitudeDifference,
        time: dayjs.unix(location.time).format("h:mm A"),
        rawTime: location.time,

        historicalLat: location.historicalLat || undefined,
        historicalLong: location.historicalLon || undefined,
        anomalyAttributeTrainingValue:
          location.anomalyAttributeTrainingValue || undefined,
        anomalyAttributeValue: location.anomalyAttributeValue || undefined,
      });

      if (!location.anomaly) {
        setAnomalyList((prev) => {
          delete prev[location.icao];
          return prev;
        });
      }
      if (location.anomaly) {
        setAnomalyList((prev) => ({ ...prev, [location.icao]: location }));
      }
      //end fetch
    }); //end map

    setObjects(Object.keys(parsedLocationData));
    setLocationData(parsedLocationData);
    setLocationDataCount(Object.keys(parsedLocationData).length);
    setFlightLocationData(parsedLocationData);
    rawLocationData.current.flights = [];

    console.log(rawLocationData.current.flights, "rawLocationDataFlights");
    console.count("UPDATE");
  };

  const genMarineLocations = () => {
    console.log(rawLocationData, "rawLocationDataMarine");

    const parsedLocationData = marineLocationData;

    rawLocationData.current.marine.map((location) => {
      parsedLocationData[location.MMSI] =
        parsedLocationData[location.MMSI] || [];

      const parsedLocation = {
        mmsi: String(location.MMSI),
        lat: location.LATITUDE,
        long: location.LONGITUDE,
        head: location.HEADING,
        anom: location.anomaly || undefined,
        anomalyType: location.anomalyType || undefined,
        callsign: location.CALLSIGN,
        velocity: location.SPEED,
        course: location.COURSE,
        time: getEpochMillis(location.TIMESTAMP),
        rawTime: getEpochMillis(location.TIMESTAMP),
        historicalLat: location.historicalLat || undefined,
        historicalLong: location.historicalLon || undefined,
        anomalyAttributeTrainingValue:
          location.anomalyAttributeTrainingValue || undefined,
        anomalyAttributeValue: location.anomalyAttributeValue || undefined,
      };
      parsedLocationData[location.MMSI].push(parsedLocation);

      if (!location.anomaly) {
        setAnomalyList((prev) => {
          delete prev[location.MMSI];
          return prev;
        });
      }
      if (location.anomaly) {
        setAnomalyList((prev) => ({
          ...prev,
          [location.MMSI]: parsedLocation,
        }));
      }
    });

    setObjects(Object.keys(parsedLocationData));
    setLocationData(parsedLocationData);
    setLocationDataCount(Object.keys(parsedLocationData).length);
    setMarineLocationData(parsedLocationData);
    rawLocationData.current.marine = [];

    console.log(rawLocationData.current.marine, "rawLocationDataMarine");
    console.count("UPDATE");
  };

  const genSpaceLocations = () => {
    console.log(rawLocationData, "rawLocationDataSpace");

    const parsedLocationData = spaceLocationData;

    rawLocationData.current.space.map((location) => {
      const satrec = satPre.twoline2satrec(
        location.TLE_LINE1,
        location.TLE_LINE2
      );
      const date = new Date();
      const positionAndVelocity = satPre.propagate(satrec, date);
      const jd = satPre.jday(
        date.getUTCFullYear(),
        date.getUTCMonth() + 1,
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );
      const gmst = satPre.gstime(jd);

      let latitude = 0;
      let longitude = 0;
      let altitude = 0;

      if (positionAndVelocity && positionAndVelocity.position) {
        const latLon = satPre.eciToGeodetic(positionAndVelocity.position, gmst);

        longitude = satPre.degreesLong(latLon.longitude);
        latitude = satPre.degreesLat(latLon.latitude);
        altitude = latLon.height;
      }

      parsedLocationData[location.OBJECT_ID] =
        parsedLocationData[location.OBJECT_ID] || [];

      const parsedLocation = {
        object_id: String(location.OBJECT_ID),
        lat: latitude,
        long: longitude,
        altitude: altitude,
        anom: location.anomaly || undefined,
        anomalyType: location.anomalyType || undefined,
        cat_id: location.NORAD_CAT_ID,
        object_name: location.OBJECT_NAME,
        historicalLat: location.historicalLat || undefined,
        historicalLong: location.historicalLon || undefined,
        anomalyAttributeTrainingValue:
          location.anomalyAttributeTrainingValue || undefined,
        anomalyAttributeValue: location.anomalyAttributeValue || undefined,
      };

      parsedLocationData[location.OBJECT_ID].push(parsedLocation);

      if (!location.anomaly) {
        setAnomalyList((prev) => {
          delete prev[location.OBJECT_ID];
          return prev;
        });
      }
      if (location.anomaly) {
        setAnomalyList((prev) => ({
          ...prev,
          [location.OBJECT_ID]: parsedLocation,
        }));
      }
    });

    for (let location in parsedLocationData) {
      if (
        parsedLocationData[location].some(
          (location) =>
            location.lat === 0 && location.long === 0 && location.altitude === 0
        )
      ) {
        delete parsedLocationData[location];
      }
    }

    setObjects(Object.keys(parsedLocationData));
    setLocationData(parsedLocationData);
    setLocationDataCount(Object.keys(parsedLocationData).length);
    setSpaceLocationData(parsedLocationData);
    rawLocationData.current.space = [];

    console.log(rawLocationData.current.space, "rawLocationDataspace");
    console.count("UPDATE");
  };

  useEffect(() => {
    if ((aor.length == 4 || aor == false) && mainFocus == "Flights") {
      console.log("NEW CONNECTION");

      if (flightSSE.current) {
        flightSSE.current.close();
      }

      console.log(serverLocation);
      flightSSE.current = new EventSource(
        `${serverLocation}/getflightdata?airport=${aor}&anomalyDetect=${anomalyMode}&leftTopLat=${manualCoors.leftLat}&leftTopLon=${manualCoors.leftLon}&rightBotLat=${manualCoors.rightLat}&rightBotLon=${manualCoors.rightLon}&anomalyStrength=${anomalyStrength}`
      );

      flightSSE.current.onopen = () => {
        console.log("Connection made");
      };
      flightSSE.current.onerror = (err) => {
        console.log("There was an error from server", err);
      };
      flightSSE.current.onmessage = (event) => {
        rawLocationData.current.flights.push(JSON.parse(event.data));
      };
    }
  }, [
    aor,
    anomalyMode,
    mainFocus,
    manualCoors,
    serverLocation,
    anomalyStrength,
  ]);

  useEffect(() => {
    if (mainFocus == "Marine") {
      console.log("MARINE");

      clearInterval(marineTick.current.dataFetcher);

      marineTick.current.dataFetcher = setInterval(() => {
        axios
          .get(
            `${serverLocation}/getmarinedata?anomalyDetect=${anomalyMode}&anomalyStrength=${anomalyStrength}`
          )
          .then((res) => {
            console.log(res.data);

            res.data.map((ship) => {
              rawLocationData.current.marine.push(ship.AIS);
            });
          });
      }, interval * 1000);
    }
  }, [mainFocus, interval, serverLocation, anomalyMode, anomalyStrength]);

  useEffect(() => {
    if (mainFocus == "Space") {
      console.log("SPACE");

      clearInterval(spaceTick.current.dataFetcher);

      spaceTick.current.dataFetcher = setInterval(() => {
        axios.get("http://127.0.0.1:5000/").then((res) => {
          console.log(res.data);

          res.data.map((satellite) => {
            rawLocationData.current.space.push(satellite);
          });
        });
      }, interval * 1000);
    }
  }, [mainFocus, interval, serverLocation, anomalyMode, anomalyStrength]);

  useEffect(() => {
    clearData();
  }, [mainFocus]);

  console.log(selectedObjects, anomalyList, locationData, "info");
  return (
    <div>
      {mainFocus != "Space" && (
        <MapContainer
          doubleClickZoom={false}
          scrollWheelZoom={true}
          zoomControl={true}
          className="2xl:w-[75rem] xl:w-[50rem] md:w-[40rem] w-[30rem] h-[51rem] rounded-3xl"
          whenCreated={setMap}
        >
          <Recenter
            manualCor={manualCoors}
            resetZoom={resetZoom}
            mainFocus={mainFocus}
          />
          <TileLayer
            attribution="&copy; Epitome Research and Innovations Inc."
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {weatherToggle && (
            <TileLayer
              attribution='&copy; OpenWeatherMap <a href="http://openweathermap.org">OpenWeatherMap</a>'
              url={`https://tile.openweathermap.org/map/precipitation_cls/{z}/{x}/{y}.png?appid=0c6a31d43344b2d1d914910408f806ee`}
            />
          )}
          <Legend map={map} mainFocus={mainFocus} />
          {/* <TileLayer
          url={`https://tile.openweathermap.org/map/precipitation/{z}/{x}/{y}.png?appid=0c6a31d43344b2d1d914910408f806ee`}
        /> */}
          {objects.map((key) => {
            return (
              <RotatedMarker
                key={key}
                icon={generateIcon(
                  mainFocus,
                  locationData[key][locationData[key].length - 1].anom,
                  anomalyMode,
                  selectedObjects.includes(key)
                )}
                position={[
                  locationData[key][locationData[key].length - 1].lat,
                  locationData[key][locationData[key].length - 1].long,
                ]}
                rotationAngle={
                  locationData[key][locationData[key].length - 1].head
                }
                eventHandlers={{
                  click: () => {
                    if (selectedObjects.includes(key)) {
                      setSelectedObjects(
                        selectedObjects.filter((object) => object != key)
                      );
                    } else {
                      setSelectedObjects([...selectedObjects, key]);
                    }
                  },
                }}
              >
                <Popup>
                  <div className="flex flex-col text-md">
                    {mainFocus == "Flights" && (
                      <>
                        <h1 className="mb-1">
                          <span className="font-bold">ICAO:</span> {key} |{" "}
                          <span className="font-bold">CALL SIGN:</span>{" "}
                          {
                            locationData[key][locationData[key].length - 1]
                              .callsign
                          }
                        </h1>
                        <hr className="mb-2"></hr>
                      </>
                    )}
                    {mainFocus == "Marine" && (
                      <>
                        <h1 className="mb-1">
                          <span className="font-bold">MMSI:</span> {key} |{" "}
                          <span className="font-bold">CALL SIGN:</span>{" "}
                          {
                            locationData[key][locationData[key].length - 1]
                              .callsign
                          }
                        </h1>
                        <hr className="mb-2"></hr>
                      </>
                    )}
                    {mainFocus == "Space" && (
                      <>
                        <h1 className="mb-1">
                          <span className="font-bold">CAT ID:</span>{" "}
                          {
                            locationData[key][locationData[key].length - 1]
                              .cat_id
                          }{" "}
                          | <span className="font-bold">OBJECT ID:</span> {key}
                        </h1>
                        <hr className="mb-2"></hr>
                      </>
                    )}
                    <div className="flex gap-1">
                      <h1 className="font-bold">Latitude: </h1>
                      <h1>
                        {locationData[key][locationData[key].length - 1].lat}
                      </h1>
                    </div>
                    <div className="flex gap-1">
                      <h1 className="font-bold">Longitude: </h1>
                      <h1>
                        {locationData[key][locationData[key].length - 1].long}
                      </h1>
                    </div>
                    {mainFocus == "Marine" && (
                      <>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Speed on Ground (kt): </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .velocity
                            }{" "}
                          </h1>
                        </div>
                        <div className="flex gap-1">
                          <h1 className="font-bold">
                            Course over Ground (deg):{" "}
                          </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .course
                            }
                          </h1>
                        </div>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Heading (deg): </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .head
                            }
                          </h1>
                        </div>
                      </>
                    )}
                    {mainFocus == "Flights" && (
                      <>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Ground Speed (kt): </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .velocity
                            }{" "}
                          </h1>
                        </div>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Altitude (ft): </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .altitude
                            }
                          </h1>
                        </div>
                      </>
                    )}
                    {mainFocus == "Space" && (
                      <>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Altitude (km): </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .altitude
                            }{" "}
                          </h1>
                        </div>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Object Name: </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .object_name
                            }{" "}
                          </h1>
                        </div>
                      </>
                    )}
                    {mainFocus == "Flights" && (
                      <>
                        <div className="flex gap-1">
                          <h1 className="font-bold">
                            Number of Samples Used for Training via ML:{" "}
                          </h1>
                          <h1>
                            {locationData[key][locationData[key].length - 1]
                              .historicalLat &&
                              locationData[key][locationData[key].length - 1]
                                .historicalLat.length}
                          </h1>
                        </div>
                      </>
                    )}
                  </div>
                </Popup>
              </RotatedMarker>
            );
          })}
          {objects.map((key) => {
            if (selectedObjects?.includes(key)) {
              return (
                <Polyline
                  key={key}
                  positions={[
                    locationData[key].map((update) => {
                      return [update.lat, update.long];
                    }),
                  ]}
                  pathOptions={
                    locationData[key][locationData[key].length - 1].anom
                      ? { color: "red" }
                      : { color: mainFocus == "Flights" ? "blue" : "black" }
                  }
                />
              );
            }
          })}
          {/* {objects.map((key) => {
          if (
            selectedObjects?.includes(key) &&
            locationData[key][locationData[key].length - 1].anom &&
            anomalyMode
          ) {
            return (
              <Polyline
                key={key}
                //delete this line to removed dotted line
                dashArray="1, 5"
                positions={[
                  locationData[key][
                    locationData[key].length - 1
                  ].historicalLong.map((_, i) => {
                    return [
                      locationData[key][locationData[key].length - 1]
                        .historicalLat[i],
                      locationData[key][locationData[key].length - 1]
                        .historicalLong[i],
                    ];
                  }),
                ]}
                pathOptions={{ color: "green" }}
              >
                <Popup>
                  <div className="flex flex-col text-md">
                    <h1 className="text-md mb-1 font-bold">
                      Historical fight path corresponding to
                    </h1>
                    <h1>
                      ICAO: {key}, Call Sign:{" "}
                      {locationData[key][locationData[key].length - 1].callsign}
                    </h1>
                  </div>
                </Popup>
              </Polyline>
            );
          }
        })} */}

          {objects.map((key) => {
            if (
              historyToggle &&
              selectedObjects?.includes(key) &&
              locationData[key][locationData[key].length - 1].anom &&
              anomalyMode
            ) {
              return locationData[key][
                locationData[key].length - 1
              ].historicalLong.map((_, i) => {
                return (
                  <Marker
                    position={[
                      locationData[key][locationData[key].length - 1]
                        .historicalLat[i],
                      locationData[key][locationData[key].length - 1]
                        .historicalLong[i],
                    ]}
                    icon={generateIcon("dot")}
                  ></Marker>
                );
              });
            }
          })}

          <h1 className="text-white">{locationData.length}</h1>
        </MapContainer>
      )}
      {/* <div ref={mapGlobeContainer} className="w-full h-[40rem]" /> */}
      <div className="2xl:w-[75rem] xl:w-[50rem] md:w-[40rem] w-[30rem] h-[51rem] rounded-3xl">
        {/* <ReactMapGl
          mapLib={import("mapbox-gl")}
          initialViewState={{
            longitude: -100,
            latitude: 40,
            zoom: 3.5,
          }}
          style={{ width: 600, height: 400 }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken="
            pk
              .eyJ1IjoiZ2FtZXN0dWRpbyIsImEiOiJjbG5qcmcwN3MxamsyMmxxeDE2eHpiZ2FnIn0
              .L9wDjSWtOYI6phjUMEGEGw
          "
        ></ReactMapGl> */}
        {mainFocus == "Space" && (
          <MapBox
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              latitude: 37.8,
              longitude: -122.4,
              zoom: 14,
            }}
            style={{ width: "100%", height: "100%" }}
            mapStyle="mapbox://styles/gamestudio/clnjsnk76001b01qjfm39a8ld"
            projection="globe"
          >
            {objects.map((key) => {
              return (
                <MapBoxMarker
                  key={key}
                  latitude={locationData[key][locationData[key].length - 1].lat}
                  longitude={
                    locationData[key][locationData[key].length - 1].long
                  }
                  anchor="bottom"
                  rotation={
                    locationData[key][locationData[key].length - 1].head
                  }
                  onClick={() => {
                    if (selectedObjects.includes(key)) {
                      setSelectedObjects(
                        selectedObjects.filter((object) => object != key)
                      );
                    } else {
                      setSelectedObjects([...selectedObjects, key]);
                    }
                  }}
                  className="cursor-pointer"
                >
                  <img
                    width="30px"
                    src={require("../../Assets/Space/Satellite.png")}
                  />
                </MapBoxMarker>
              );
            })}
            {selectedObjects.map((key) => {
              return (
                <MapBoxPopup
                  key={key}
                  closeOnClick={false}
                  latitude={locationData[key][locationData[key].length - 1].lat}
                  longitude={
                    locationData[key][locationData[key].length - 1].long
                  }
                  anchor="top"
                >
                  <div className="flex flex-col text-md">
                    {mainFocus == "Space" && (
                      <>
                        <h1 className="mb-1">
                          <span className="font-bold">CAT ID:</span>{" "}
                          {
                            locationData[key][locationData[key].length - 1]
                              .cat_id
                          }{" "}
                          | <span className="font-bold">OBJECT ID:</span> {key}
                        </h1>
                        <hr className="mb-2"></hr>
                      </>
                    )}
                    <div className="flex gap-1">
                      <h1 className="font-bold">Latitude: </h1>
                      <h1>
                        {locationData[key][locationData[key].length - 1].lat}
                      </h1>
                    </div>
                    <div className="flex gap-1">
                      <h1 className="font-bold">Longitude: </h1>
                      <h1>
                        {locationData[key][locationData[key].length - 1].long}
                      </h1>
                    </div>

                    {mainFocus == "Space" && (
                      <>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Altitude (km): </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .altitude
                            }{" "}
                          </h1>
                        </div>
                        <div className="flex gap-1">
                          <h1 className="font-bold">Object Name: </h1>
                          <h1>
                            {
                              locationData[key][locationData[key].length - 1]
                                .object_name
                            }{" "}
                          </h1>
                        </div>
                      </>
                    )}
                  </div>
                </MapBoxPopup>
              );
            })}
          </MapBox>
        )}
      </div>
    </div>
  );
};

export default Map;
