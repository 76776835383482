import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Landing from "./Components/Landing/Landing";
import Home from "./Components/Home/Home";
import ProtectedRoute from "./Components/ProtectedRoute";

import { Auth0Provider } from "@auth0/auth0-react";

function App() {
  return (
    <>
      <BrowserRouter>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL}
          cacheLocation="localstorage"
          useRefreshTokens={true}
        >
          <Routes>
            <Route path="/" element={<Landing />} />

            <Route path="/home" element={<Home />} />
          </Routes>
        </Auth0Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
